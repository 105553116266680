






import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";

import HomeScroller from "@/components/HomeScroller.vue";
import Banner from "@/components/Banner.vue";

import { Game, HomeSection } from "@/store/modules/games";

@Component({
  components: {
    HomeScroller,
    Banner,
  },
})
export default class HomeSectionComponent extends Vue {
  @Prop(Object) readonly data: HomeSection;

  get games(): Game[] {
    return this.data.games
      .slice()
      .sort((a, b) => a.order - b.order)
      .map((v) => this.getGame(v.game))
      .filter((g) => g);
  }

  getGame(id: string): Game {
    return this.$store.direct.state.games.games.find((game) => game._id === id) as Game;
  }
}
