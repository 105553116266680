














































































































import Vue from "vue";
import { Component } from "vue-property-decorator";

import i18n from "@/plugins/i18n";

import Banner from "@/components/Banner.vue";
import HomeScroller from "@/components/HomeScroller.vue";
import HomePlayTile from "@/components/HomePlayTile.vue";
import HomeSectionComponent from "@/components/HomeSection.vue";

import { Game, HomeSection } from "@/store/modules/games";

@Component({
  components: {
    HomeScroller,
    Banner,
    HomePlayTile,
    HomeSectionComponent,
  },
})
export default class HomeView extends Vue {
  steps = [
    {
      target: ".dynamic",
      header: {
        title: i18n.t("home.tour.step0.title").toString(),
      },
      content: i18n.t("home.tour.step0.content").toString(),
      params: {
        placement: "top",
        highlight: true,
      },
    },
    {
      target: ".dynamic",
      header: {
        title: i18n.t("home.tour.step1.title").toString(),
      },
      content: i18n.t("home.tour.step1.content").toString(),
      params: {
        placement: "top",
        highlight: true,
      },
    },
    {
      target: "#profilButton",
      header: {
        title: i18n.t("home.tour.step2.title").toString(),
      },
      content: i18n.t("home.tour.step2.content").toString(),
      params: {
        highlight: true,
      },
    },
  ];

  myCallbacks = {
    onStart: this.onStartTour,
    onFinish: this.onFinishTour,
  };

  get myRecentGames(): Game[] {
    return this.$store.direct.state.games.recentGames
      ?.slice(0, 1)
      ?.map((recentGame) => this.$store.direct.state.games.games.find((game) => game._id === recentGame) as Game)
      ?.filter((g) => g);
  }

  get myFavoriteGames(): Game[] {
    return this.$store.direct.state.games.favoriteGames
      .map((favoriteGame) => this.$store.direct.state.games.games.find((game) => game._id === favoriteGame) as Game)
      .filter((game) => !this.myRecentGames.includes(game))
      .filter((g) => g);
  }

  get homeSections(): HomeSection[] {
    if (this.$store.direct.state.games.homeSections.length > 0) this.startTuto();
    return this.$store.direct.state.games.homeSections;
  }

  async goProfile(): Promise<void> {
    if (!this.$store.direct.state.authentication.user) throw new Error("Cannot go to profile if guest");

    this.$router.push("/profile");
  }

  async startTuto(): Promise<void> {
    await this.$nextTick();
    if (this.$store.direct.state.mobile.noMoreHomeTour) return;
    this.$tours["myTour"].start();
  }

  onStartTour(): void {
    document.querySelector("html")?.classList.add("noscroll");
  }

  onFinishTour(): void {
    document.querySelector("html")?.classList.remove("noscroll");
    // Use the store to commit no more tour.
    this.$store.direct.commit.mobile.SET_NO_MORE_HOME_TOUR(true);
  }
}
